import { cva as _cva } from "class-variance-authority"

export const cva = _cva as <T>(
  base?: ClassValue,
  config?: Config<T>,
) => (props?: Props<T>) => string

export const cx = (...classes: ClassValue[]) => classes.flat().filter(Boolean).join(" ")

// #region Types

export type ClassValue = string | number | boolean | ClassValue[] | null | undefined

export type VariantProps<Component> = Component extends (props?: infer T) => string
  ? Omit<T, "className">
  : never

// #endregion

// #region Internal cva types

type ConfigSchema = Record<string, Record<string, ClassValue>>

type Config<T> = T extends ConfigSchema
  ? {
      variants?: T
      defaultVariants?: ConfigVariants<T>
      compoundVariants?: (T extends ConfigSchema
        ? (ConfigVariants<T> | ConfigVariantsMulti<T>) & ClassProp
        : ClassProp)[]
    }
  : never

type ConfigVariants<T extends ConfigSchema> = {
  [Variant in keyof T]?: StringToBoolean<keyof T[Variant]>
}

type ConfigVariantsMulti<T extends ConfigSchema> = {
  [Variant in keyof T]?:
    | StringToBoolean<keyof T[Variant]>
    | StringToBoolean<keyof T[Variant]>[]
}
type StringToBoolean<T> = T extends "true" | "false" ? boolean : T

type ClassProp = {
  className?: ClassValue
}

type Props<T> = T extends ConfigSchema ? ConfigVariants<T> & ClassProp : ClassProp

// #endregion
